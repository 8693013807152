import React from 'react';
import { Tournament } from 'api/tournaments';
import { ListItem } from 'components/List/ListItem';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { LinkButton } from 'pages/shared/LinkButton';
import { AnchorButton } from 'components/Button/AnchorButton';

type Props = {
  key: number;
  tournament: Tournament;
};

export const TournamentItem: React.FC<Props> = ({ tournament }) => {
  return (
    <ListItem>
      <Card>
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="md:flex-1">
            <div>
              <Typography className="font-bold">大会名/要項/日程</Typography>
            </div>
            <div className="md:ml-5">
              <LinkButton
                textStyle="RegularBold"
                to={`/tournaments/${tournament.id}`}>
                {tournament.tournamentName}
              </LinkButton>
            </div>
            <div className="flex justify-between md:flex-col">
              {tournament.outlineAttachedFilePath && (
                <div className="md:ml-5">
                  <AnchorButton
                    target="_blank"
                    href={tournament.outlineAttachedFilePath}>
                    <Typography type="regular" className="font-bold">
                      大会要項（PDF）
                    </Typography>
                  </AnchorButton>
                </div>
              )}
              <div className="md:ml-5">
                {tournament.scheduleAttachedFilePath && (
                  <AnchorButton
                    target="_blank"
                    href={tournament.scheduleAttachedFilePath}>
                    <Typography type="regular" className="font-bold">
                      大会日程（PDF）
                    </Typography>
                  </AnchorButton>
                )}
              </div>
            </div>
            <div className="flex justify-between md:flex-col">
              <div>
                <div>
                  <Typography className="font-bold">開催期間</Typography>
                </div>
                <div className="md:ml-5">
                  <Typography>{tournament.holdingPeriod}</Typography>
                </div>
              </div>
              <div>
                <div>
                  <Typography className="font-bold">大会状況</Typography>
                </div>
                <div className="md:ml-5">
                  <Typography>{tournament.state}</Typography>
                </div>
              </div>
            </div>
          </div>
          <table className="table mt-5 md:mt-0 md:w-tennisEventTable">
            <thead>
              <tr>
                <th>種目</th>
                <th>ドロー表</th>
              </tr>
            </thead>
            <tbody>
              {tournament.tennisEvents.map((tennisEvent) => (
                <tr key={tennisEvent.tennisEventId}>
                  <td>
                    <LinkButton
                      textStyle="RegularBold"
                      to={`/tournaments/${tournament.id}/result/${tennisEvent.tennisEventId}`}>
                      {tennisEvent.tennisEventName}
                    </LinkButton>
                  </td>
                  <td className="text-center">
                    {DrawTableLnik(tennisEvent.drawTableAttachedFilePath)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </ListItem>
  );
};

const DrawTableLnik = (path: string) => {
  if (path) {
    return (
      <AnchorButton target="_blank" href={path}>
        <Typography type="regular" className="font-bold">
          PDF
        </Typography>
      </AnchorButton>
    );
  }

  return '-';
};
