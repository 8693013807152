import React from 'react';

type State = {
  playerName: string;
  gender: string;
  page: string;
  canSearched: boolean;
};

const initialState: State = {
  playerName: '',
  gender: '0',
  page: '0',
  canSearched: false,
};

type Action =
  | {
      type: 'UPDATE_PARAM';
      payload: Omit<State, 'canSearched'>;
    }
  | { type: 'UPDATE_PAGE'; payload: { page: string } };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_PARAM': {
      const { playerName, gender, page } = action.payload;
      return {
        playerName,
        gender,
        page,
        canSearched: true,
      };
    }
    case 'UPDATE_PAGE': {
      return {
        ...state,
        page: action.payload.page,
      };
    }
    default:
      return state;
  }
};

type PlayerStoreType = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const PlayersStore = React.createContext<PlayerStoreType>({
  state: initialState,
  dispatch: () => null,
});

const PlayersStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <PlayersStore.Provider value={{ state, dispatch }}>
      {children}
    </PlayersStore.Provider>
  );
};

const usePlayersStore = (): PlayerStoreType => React.useContext(PlayersStore);

export { PlayersStore, PlayersStoreProvider, usePlayersStore };
