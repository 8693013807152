import React from 'react';
import { AnchorProps, AnchorButtonBase } from 'components/Button/ButtonBase';

export const AnchorButton: React.FC<AnchorProps> = ({ ...props }) => {
  return (
    <AnchorButtonBase
      className="text-link hover:text-link-hover cursor-pointer underline"
      {...props}
    />
  );
};

// react-router-dom用
export const FancyAnchorButton = React.forwardRef<
  HTMLAnchorElement,
  AnchorProps
>(function link(props, ref) {
  const { href, target } = props;
  const rel = target === '_blank' ? 'noopener noreferrer' : '';
  return (
    <a
      ref={ref}
      className="text-link hover:text-link-hover cursor-pointer underline"
      href={href}
      target={target}
      rel={rel}>
      {props.children}
    </a>
  );
});
