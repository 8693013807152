import React from 'react';
import { Block, AssignedPlayer } from 'api/drawTables';
import { Typography } from 'components/Typography/Typography';
import { Draw } from 'pages/Tournaments/Draw';

export type Game = {
  gameNumber: number;
  winnerNames?: string[];
  gameScore: string;
};

export const DrawTable: React.FC<{
  block: Block;
  players: AssignedPlayer[];
  isDoubles: boolean;
}> = ({ block, players, isDoubles }) => {
  const drawClassNames = `print-area ${isDoubles ? 'doubles' : ''}`;
  const games: Game[] = [];
  const gameNumbers = [...Array(block.drawCount - 1)].map((v, i) => i + 1);
  for (const gameNumber of gameNumbers) {
    const gameResult = block.gameResults.find(
      (o) => o.gameNumber === gameNumber
    );

    if (gameResult == null) {
      games[gameNumber] = {
        gameNumber: gameNumber,
        winnerNames: [],
        gameScore: '',
      };

      continue;
    }

    const winner = players.find(
      (o) => o.entryNumber === gameResult.entryNumberOfWinner
    );

    games[gameNumber] = {
      gameNumber: gameNumber,
      winnerNames: winner?.playerNames || [],
      gameScore: gameResult.gameScore || '',
    };
  }

  return (
    <div className="overflow-x-auto">
      <Typography className="font-bold">{block.blockName}</Typography>
      <div className={drawClassNames}>
        <Draw players={players} games={games} />
      </div>
    </div>
  );
};
