import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTournament } from 'api/tournaments';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { Spinner } from 'components/Loading/Spinner';
import { AnchorButton } from 'components/Button/AnchorButton';
import { PostContainer } from 'pages/shared/PostContainer';

export const TournamentPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { tournamentOutline, isLoading, isError } = useTournament(Number(id));
  if (isLoading || isError) {
    return <Spinner />;
  }

  return (
    <>
      <Card>
        <Heading type="heading4">{tournamentOutline.tournamentName}</Heading>
        <div className="mt-5">
          <PostContainer content={tournamentOutline.outline} />
        </div>
      </Card>
      <div className="mt-5">
        <AnchorButton onClick={() => history.goBack()}>
          <Typography>
            <svg
              className="stroke-current inline-block"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <path d="M19 12H6M12 5l-7 7 7 7" />
            </svg>
            一覧へ戻る
          </Typography>
        </AnchorButton>
      </div>
    </>
  );
};
