import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRanking, RankingPlayer } from 'api/rankings';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';
import { Spinner } from 'components/Loading/Spinner';
import { List } from 'components/List/List';
import { RankingPlayerItem } from 'pages/Rankings/RankingPlayerItem';
import { Button } from 'components/Button/Button';

export const RankingPage: React.FC = () => {
  const { tennis_event_id } = useParams<{ tennis_event_id: string }>();

  const history = useHistory();
  const queryString = new URLSearchParams(history.location.search);
  const page_size = queryString.get('page_size');
  const parsedPageSize = page_size == null ? 1 : parseInt(page_size, 10) || 1;
  const {
    ranking,
    isLoading,
    isLoadingMore,
    isReachingEnd,
    isError,
    size,
    next,
  } = useRanking(tennis_event_id, parsedPageSize);
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <div className="py-8">
        <Typography type="large">読み込みに失敗しました。</Typography>
      </div>
    );
  }

  const handleClickNext = () => {
    next();
    history.replace({
      pathname: history.location.pathname,
      search: `?page_size=${size + 1}`,
    });
  };

  return (
    <>
      <div className="flex justify-items-center">
        <SectionHeading>ランキング一覧</SectionHeading>
      </div>
      <div className="my-5">
        <Typography tag="p">
          選手をクリックすると選手の詳細情報が表示されます。
        </Typography>
      </div>
      <Heading type="heading4">{ranking.rankingName}</Heading>
      <div className="my-3">
        <Typography tag="p" className="text-right">
          {`更新日時：${ranking.updatedDateTime}`}
        </Typography>
      </div>
      <RankingPlayerList rankingPlayers={ranking.players} />
      {isReachingEnd ? (
        <></>
      ) : isLoadingMore ? (
        <Spinner />
      ) : (
        <Button onClick={handleClickNext}>読み込む</Button>
      )}
    </>
  );
};

const RankingPlayerList: React.FC<{ rankingPlayers: RankingPlayer[] }> = ({
  rankingPlayers,
}) => {
  const rankingPlayerList = rankingPlayers.map((item) => (
    <RankingPlayerItem key={item.id} rankingPlayer={item} />
  ));
  return (
    <>
      <div className="grid grid-cols-ranking-row md:grid-cols-ranking-row-tablet border-b-2 border-gray text-center p-5">
        <div>
          <Typography className="font-bold">順位</Typography>
        </div>
        <div>
          <Typography className="font-bold">ポイント</Typography>
        </div>
        <div>
          <Typography className="font-bold">氏名</Typography>
        </div>
        <div>
          <Typography className="font-bold">団体名</Typography>
        </div>
      </div>
      <List>{rankingPlayerList}</List>
    </>
  );
};
