import React from 'react';
import { PlayerHeader } from 'api/players';
import { ListItem } from 'components/List/ListItem';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { LinkButton } from 'pages/shared/LinkButton';

type Props = {
  key: number;
  player: PlayerHeader;
};

export const PlayerItem: React.FC<Props> = ({ player }) => {
  return (
    <ListItem>
      <Card>
        <div className="grid grid-cols-2">
          <div>
            <LinkButton textStyle="RegularBold" to={`/players/${player.id}`}>
              {player.playerName}
            </LinkButton>
          </div>
          <div>
            <Typography tag="p" className="truncate">
              {player.teamName}
            </Typography>
          </div>
        </div>
      </Card>
    </ListItem>
  );
};
