import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { routes } from 'routes';
import { SWRContext } from 'api/SWRContext';
import { TheHeader } from 'components/Layout/TheHeader';
import { TheFooter } from 'components/Layout/TheFooter';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SWRContext>
        <TheHeader />
        <div className="flex-1 h-full bg-gray-light mt-header md:mt-0">
          <div className="container px-5 pt-8 pb-20 md:pt-20 md:pb-10">
            <Switch>
              {routes.map((route, i) => (
                <Route
                  key={i}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <TheFooter />
      </SWRContext>
    </BrowserRouter>
  );
};

export default App;
