import React from 'react';
import { DAPlayer } from 'api/drawTables';
import { Typography } from 'components/Typography/Typography';

type Props = { players: DAPlayer[] };

export const DAList: React.FC<Props> = (props) => {
  if (!props.players || props.players.length === 0) {
    return <></>;
  }

  return (
    <div className="w-full">
      <Typography className="font-bold">本戦シード</Typography>
      <div className="print-area da w-full">
        <table className="border-collapse w-full">
          <thead>
            <tr className="text-center">
              <th className="point hidden md:block">ポイント</th>
              <th className="seed">シード</th>
              <th className="player-name">氏名</th>
              <th className="team-name hidden md:block">団体名</th>
            </tr>
          </thead>
          <tbody>
            {props.players.map((player, index) => (
              <tr key={index}>
                <td className="text-right hidden md:block">
                  {player.totalPoint}
                </td>
                <td className="text-right">{player.seedNumber}</td>
                <td className="md:truncate">{multiline(player.playerNames)}</td>
                <td className="hidden md:block">
                  {multiline(player.teamNames)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const multiline = (items?: string[]) =>
  items ? items.map((o, i) => <p key={i}>{o}</p>) : null;
