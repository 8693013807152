import React from 'react';

type Tag = 'button' | 'a';

export type BaseProps = {
  children?: React.ReactNode;
  className?: string;
  wide?: boolean;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  BaseProps;
export type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  BaseProps;

const buttonFactory: <Props extends BaseProps>(tag: Tag) => React.FC<Props> = (
  tag
) => {
  const Tag = tag;

  return function button({
    children = '',
    className = '',
    wide = false,
    ...props
  }) {
    const classNames = `text-sm md:text-base font-bold ${className} ${
      wide ? 'w-full' : 'w-auto'
    }`;

    return (
      <Tag className={classNames} {...props}>
        {children}
      </Tag>
    );
  };
};

export const ButtonBase: React.FC<ButtonProps> = buttonFactory<ButtonProps>(
  'button'
);
export const AnchorButtonBase: React.FC<AnchorProps> = buttonFactory<
  AnchorProps
>('a');
