import React from 'react';
import { ButtonProps, ButtonBase } from 'components/Button/ButtonBase';

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  ...props
}) => {
  return (
    <ButtonBase
      className="text-primary bg-white hover:text-white hover:bg-primary
    text-center leading-none inline-block rounded-lg border-2 shadow border-primary py-3 px-4"
      type={type}
      {...props}
    />
  );
};
