import React from 'react';

type TypographyType = 'large' | 'regular' | 'small' | 'caption' | 'quote';
type TagType = 'p' | 'span';

type Props = {
  type?: TypographyType;
  tag?: TagType;
  className?: string;
};

const getTypographyCss = (type: TypographyType) => {
  switch (type) {
    case 'large':
      return 'text-base md:text-lg';
    case 'regular':
      return 'text-sm md:text-base';
    case 'small':
      return 'text-xs md:text-sm';
    case 'caption':
      return 'text-gray-textLight text-xs';
    case 'quote':
      return 'text-gray-textLight text-sm md:text-base';
    default:
      return 'md:text-base';
  }
};

export const Typography: React.FC<Props> = ({
  type = 'regular',
  tag = 'span',
  className,
  children,
}) => {
  const Tag = tag;
  const classNames = `${getTypographyCss(type)} ${className}`;
  return <Tag className={classNames}>{children}</Tag>;
};
