import { TopPage } from 'pages/Top/TopPage';
import { AnnouncementListPage } from 'pages/Announcements/AnnouncementListPage';
import { AnnouncementPage } from 'pages/Announcements/AnnouncementPage';
import { TournamentListPage } from 'pages/Tournaments/TournamentListPage';
import { TournamentPage } from 'pages/Tournaments/TournamentPage';
import { PlayerListPage } from 'pages/Players/PlayerListPage';
import { PlayerPage } from 'pages/Players/PlayerPage';
import { RankingListPage } from 'pages/Rankings/RankingListPage';
import { RankingPage } from 'pages/Rankings/RankingPage';
import { GameResultPage } from 'pages/Tournaments/GameResultPage';

export const routes = [
  {
    path: '/',
    component: TopPage,
    menu: {
      order: 0,
      title: 'トップ',
    },
  },
  {
    path: '/announcements/news',
    component: AnnouncementListPage,
  },
  {
    path: '/announcements/guide',
    component: AnnouncementListPage,
  },
  {
    path: '/announcements/:id',
    component: AnnouncementPage,
  },
  {
    path: '/tournaments',
    component: TournamentListPage,
    menu: {
      order: 1,
      title: '大会情報',
    },
  },
  {
    path: '/tournaments/previous',
    component: TournamentListPage,
  },
  {
    path: '/tournaments/:id',
    component: TournamentPage,
  },
  {
    path: '/tournaments/:tournamentId/result/:tennisEventId',
    component: GameResultPage,
  },
  {
    path: '/rankings',
    component: RankingListPage,
    menu: {
      order: 2,
      title: 'ランキング',
    },
  },
  {
    path: '/rankings/:tennis_event_id',
    component: RankingPage,
  },
  {
    path: '/players',
    component: PlayerListPage,
    menu: {
      order: 3,
      title: '選手情報',
    },
  },
  {
    path: '/players/:id',
    component: PlayerPage,
  },
];
