import React from 'react';
import { SWRConfig } from 'swr';

export type LoadState = {
  isLoading: boolean;
  isError: boolean;
};

const fetcher = async (url: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`);
  if (!res.ok) {
    throw new Error('An error occurred while fetching the data.');
  }

  return res.json();
};

export const SWRContext: React.FC = ({ children }) => {
  return (
    <>
      <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
    </>
  );
};
