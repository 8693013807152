import React from 'react';
import { EarnedPoint } from 'api/players';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { LinkButton } from 'pages/shared/LinkButton';

export const PointHistory: React.FC<{
  earnedPoints: EarnedPoint[];
}> = ({ earnedPoints }) => {
  if (!earnedPoints.length) {
    return <Typography type="large">獲得したポイントはありません。</Typography>;
  }

  const points = earnedPoints.map((item) => (
    <PointItem key={item.pointEventName} earnedPoint={item} />
  ));

  return (
    <Card>
      <div className="grid gap-1 grid-cols-point-row md:grid-cols-point-row-tablet border-b-2 border-gray">
        <div>
          <Typography className="font-bold">大会名</Typography>
        </div>
        <div className="md:hidden text-center">
          <Typography className="font-bold">ポイント/カテゴリ</Typography>
        </div>
        <div className="md:hidden text-center">
          <Typography className="font-bold">種別/順位</Typography>
        </div>
        <div className="hidden md:block text-center">
          <Typography className="font-bold">ポイント</Typography>
        </div>
        <div className="hidden md:block text-center">
          <Typography className="font-bold">カテゴリ</Typography>
        </div>
        <div className="hidden md:block text-center">
          <Typography className="font-bold">種別</Typography>
        </div>
        <div className="hidden md:block text-center">
          <Typography className="font-bold">順位</Typography>
        </div>
      </div>
      {points}
    </Card>
  );
};

const PointItem: React.FC<{ earnedPoint: EarnedPoint }> = ({ earnedPoint }) => {
  return (
    <div className="grid gap-1 grid-rows-2 grid-cols-point-row grid-flow-col md:grid-rows-1 md:grid-cols-point-row-tablet md:grid-flow-row py-2 border-b border-gray">
      <div className="row-span-2">
        <PointEventName earnedPoint={earnedPoint} />
      </div>
      <div className="text-center md:text-right md:pr-8">
        <Typography>{earnedPoint.point}</Typography>
      </div>
      <div className="text-center">
        <Typography>{earnedPoint.category}</Typography>
      </div>
      <div className="text-center">
        <Typography>{earnedPoint.participationClassification}</Typography>
      </div>
      <div className="text-center">
        <Typography>{earnedPoint.rank}</Typography>
      </div>
    </div>
  );
};

const PointEventName: React.FC<{ earnedPoint: EarnedPoint }> = ({
  earnedPoint,
}) => {
  if (earnedPoint.pointEventId == null || earnedPoint.tennisEventId == null) {
    return <Typography>{earnedPoint.pointEventName}</Typography>;
  }

  return (
    <LinkButton
      textStyle="RegularBold"
      to={`/tournaments/${earnedPoint.pointEventId}/result/${earnedPoint.tennisEventId}`}>
      {earnedPoint.pointEventName}
    </LinkButton>
  );
};
