import React from 'react';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const TextField: React.FC<Props> = ({ ...props }) => {
  return (
    <input
      type="text"
      {...props}
      className="border border-gray-semiLight w-full pl-4 py-2"
    />
  );
};
