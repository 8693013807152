export const convertToDom = (htmlText: string): Element | null => {
  if (htmlText == null) {
    return null;
  }

  const body = new DOMParser()
    .parseFromString(`<div>${htmlText}</div>`, 'text/html')
    .querySelector('body');
  if (body != null) {
    if (body.hasChildNodes()) {
      return body.children[0];
    }
  }

  return null;
};
