import React from 'react';
import { Heading } from 'components/Typography/Heading';

export const SectionHeading: React.FC = ({ children }) => {
  return (
    <Heading
      type="heading2"
      className="w-full text-center md:text-left mb-5 md:mb-8">
      {children}
    </Heading>
  );
};
