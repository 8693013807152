import React from 'react';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { LinkButton } from 'pages/shared/LinkButton';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';

export const RankingListPage: React.FC = () => {
  return (
    <>
      <div className="flex justify-items-center">
        <SectionHeading>ランキング</SectionHeading>
      </div>
      <div className="my-5">
        <Typography tag="p">
          表示したいランキングをクリックしてください。
        </Typography>
      </div>
      <div className="md:flex">
        <div className="mt-5 md:flex-1">
          <Card>
            <Heading type="heading4">シングルス</Heading>
            <Category name="17歳以下" category="1" format="1" />
            <Category name="15歳以下" category="2" format="1" />
            <Category name="13歳以下" category="3" format="1" />
            <Category name="11歳以下" category="4" format="1" />
          </Card>
        </div>
        <div className="mt-5 md:flex-1 md:ml-6">
          <Card>
            <Heading type="heading4">ダブルス</Heading>
            <Category name="17歳以下" category="1" format="2" />
            <Category name="15歳以下" category="2" format="2" />
            <Category name="13歳以下" category="3" format="2" />
            <Category name="11歳以下" category="4" format="2" />
          </Card>
        </div>
      </div>
    </>
  );
};

const Category: React.FC<{
  name: string;
  category: string;
  format: string;
}> = ({ name, category, format }) => {
  return (
    <div className="flex py-2">
      <Typography>{name}</Typography>
      <div className="ml-12">
        <LinkButton
          textStyle="RegularBold"
          to={`/rankings/${category}_1_${format}`}>
          男子
        </LinkButton>
      </div>
      <div className="ml-12">
        <LinkButton
          textStyle="RegularBold"
          to={`/rankings/${category}_2_${format}`}>
          女子
        </LinkButton>
      </div>
    </div>
  );
};
