import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useAnnouncements, AnnouncementType } from 'api/announcements';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { Typography } from 'components/Typography/Typography';
import { List } from 'components/List/List';
import { Spinner } from 'components/Loading/Spinner';
import { AnnouncementItem } from 'pages/Announcements/AnnouncementItem';
import { AnchorButton } from 'components/Button/AnchorButton';

export const AnnouncementListPage: React.FC = () => {
  const match = useRouteMatch('/announcements/news');
  const target: { announcementType: AnnouncementType; title: string } =
    match != null
      ? { announcementType: 'news', title: 'お知らせ一覧' }
      : { announcementType: 'guide', title: 'その他ご案内一覧' };
  const announcementList = AnnouncementList(target.announcementType);
  return (
    <>
      <SectionHeading>{target.title}</SectionHeading>
      <List>{announcementList}</List>
    </>
  );
};

const AnnouncementList = (
  announcementType: AnnouncementType
): JSX.Element | JSX.Element[] => {
  const history = useHistory();
  let pageIndex = getPageFromQuery(history.location.state);
  const [page, setPage] = useState(pageIndex);
  const {
    announcements,
    hasPreviousPage,
    hasNextPage,
    isLoading,
    isError,
  } = useAnnouncements({
    announcementType,
    page: page,
  });
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <div className="py-8">
        <Typography type="large">読み込みに失敗しました。</Typography>
      </div>
    );
  }

  const previousButton = hasPreviousPage && (
    <AnchorButton onClick={() => setPage(page - 1)}>前へ</AnchorButton>
  );

  const nextButton = hasNextPage && (
    <AnchorButton onClick={() => setPage(page + 1)}>次へ</AnchorButton>
  );
  return (
    <>
      <List>
        {announcements.map((item) => (
          <AnnouncementItem key={item.id} announcement={item} page={page} />
        ))}
      </List>
      <div className="flex justify-around">
        <div>{previousButton}</div>
        <div>{nextButton}</div>
      </div>
    </>
  );
};

const getPageFromQuery = (state: any): number => {
  if (!state || !('page' in state)) {
    return 0;
  }

  return Number(state.page);
};
