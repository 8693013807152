import useSWR from 'swr';
import { LoadState } from 'api/SWRContext';

export type AssignedPlayer = {
  blockNumber: number;
  gameNumber: number;
  drawNumber: number;
  framePlayerClassification: number;
  playerClassification: number;
  seedLevel: number;
  assignOrder: number;
  entryNumber: number;
  seedNumber: number;
  playerCodes: string[];
  playerNames: string[];
  teamAbbreviatedNames: string[];
  points: number[];
  totalPoint: number;
  fromQualifying: boolean;
};

// see: JuniorTennis.Domain.DrawTables.PlayerClassification
export const isBye = (assignedPlayer: AssignedPlayer): boolean =>
  assignedPlayer.playerClassification === 3;

export type Block = {
  blockNumber: number;
  blockName: string;
  drawCount: number;
  gameResults: GameResult[];
};

export type DAPlayer = {
  totalPoint: number;
  seedNumber: number;
  playerNames: string[];
  teamNames: string[];
};

export type GameResult = {
  brockNumber: number;
  gameNumber: number;
  gameStatus: number;
  playerClassificationOfWinner: number;
  entryNumberOfWinner: number;
  gameScore: string;
};

type DrawTablesResult = {
  result: {
    tournamentName: string;
    tennisEventName: string;
    isDoubles: boolean;
    assignedPlayers: AssignedPlayer[];
    blocks: Block[];
    daPlayers: DAPlayer[];
  };
} & LoadState;

export const useDrawTables = (
  tournamentId: string,
  tennisEventId: string
): DrawTablesResult => {
  const { data, error } = useSWR(`drawTables/${tournamentId}/${tennisEventId}`);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};
