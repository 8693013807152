import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TournamentItem } from 'pages/Tournaments/TournamentItem';
import { useTournaments } from 'api/tournaments';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';
import { List } from 'components/List/List';
import { Spinner } from 'components/Loading/Spinner';
import { LinkButton } from 'pages/shared/LinkButton';

export const TournamentListPage: React.FC = () => {
  const match = useRouteMatch('/tournaments/previous');
  const isPrevious = match != null;
  const otherPage = match
    ? {
        title: '今年度の大会はこちら',
        url: '/tournaments',
      }
    : {
        title: '前年度の大会はこちら',
        url: '/tournaments/previous',
      };
  const tournamentList = TournamentList(isPrevious);

  return (
    <>
      <div className="flex justify-items-center">
        <SectionHeading>大会一覧</SectionHeading>
      </div>
      {tournamentList}
      <div>
        <LinkButton textStyle="RegularBold" to={otherPage.url}>
          {otherPage.title}
        </LinkButton>
      </div>
    </>
  );
};

const TournamentList = (isPrevious: boolean) => {
  const { result, isLoading, isError } = useTournaments(isPrevious);
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <div className="py-8">
        <Typography type="large">読み込みに失敗しました。</Typography>
      </div>
    );
  }

  const tournamentList = result.tournaments.map((item) => (
    <TournamentItem key={item.id} tournament={item} />
  ));
  return (
    <>
      <Heading>{result.seasonName}の大会</Heading>
      <List>{tournamentList}</List>
    </>
  );
};
