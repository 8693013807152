import React from 'react';
import { useParams } from 'react-router-dom';
import { usePlayer } from 'api/players';
import { Heading } from 'components/Typography/Heading';
import { Spinner } from 'components/Loading/Spinner';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { PlayerDetail } from 'pages/Players/PlayerDetail';
import { PointHistory } from 'pages/Players/PointHistory';
import { TournamentResults } from 'pages/Players/TournamentResults';

export const PlayerPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { player, isLoading, isError } = usePlayer(Number(id));
  if (isLoading || isError) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex justify-items-center">
        <SectionHeading>選手情報</SectionHeading>
      </div>
      <PlayerDetail player={player} />
      <div className="my-5 md:mt-16 md:mb-6">
        <Heading type="heading4">ポイント獲得歴（シングルス）</Heading>
      </div>
      <PointHistory earnedPoints={player.singlesPoints} />
      <div className="my-5 md:mt-16 md:mb-6">
        <Heading type="heading4">ポイント獲得歴（ダブルス）</Heading>
      </div>
      <PointHistory earnedPoints={player.doublesPoints} />
      <div className="my-5 md:mt-16 md:mb-6">
        <Heading type="heading4">試合戦績</Heading>
      </div>
      <TournamentResults tournamentResults={player.tournamentResults} />
    </>
  );
};
