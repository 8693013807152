import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.png';
import mail from 'assets/mail.svg';
import { HamburgerButton } from 'components/Menu/HamburgerButton';
import { Navigation } from 'components/Menu/Navigation';

export const TheHeader: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const navClassNames = `container absolute md:static md:block ${
    isOpen ? 'block' : 'hidden'
  }`;

  return (
    <>
      <header className="bg-white fixed w-full top-0 z-50 md:static">
        <div className="container">
          <div className="flex h-header py-3 md:h-headerTablet md:pt-8 md:pb-0">
            <div>
              <Link to="/" onClick={() => setOpen(false)}>
                <img src={logo} alt="logo" className="w-48" />
              </Link>
            </div>
            <div className="hidden md:block ml-4">
              <h1 className="font-bold text-4xl leading-none">
                ジュニアシステム
              </h1>
              <span className="text-xs text-gray">
                大阪府テニス協会 osaka tennis association
              </span>
            </div>
            <div className="flex place-items-center ml-auto px-8 bg-primary md:-mt-8">
              <div className="h-auto">
                <a
                  className="flex"
                  href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>
                  <img src={mail} alt="mail" />
                  <span className="text-white hidden md:inline pl-2">
                    お問い合わせ
                  </span>
                </a>
              </div>
            </div>
            <div className="flex place-items-center ml-8 px-8 bg-secondary-light md:hidden">
              <HamburgerButton
                isOpen={isOpen}
                onClick={() => setOpen((prev) => !prev)}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="overflow-x-hidden">
        <nav className="fixed mt-header z-50 md:bg-secondary-light md:sticky md:mt-0 md:top-0">
          <div className="w-screen">
            <div className={navClassNames}>
              <Navigation handleMenuClicked={() => setOpen(false)} />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
