import React from 'react';
import { Link } from 'react-router-dom';
import { useLatestAnnouncements, Announcement } from 'api/announcements';
import dunlop from 'assets/DUNLOP_Logo.jpg';
import { Typography } from 'components/Typography/Typography';
import { Button } from 'components/Button/Button';
import { AnchorButton } from 'components/Button/AnchorButton';
import { Spinner } from 'components/Loading/Spinner';
import { Card } from 'components/Card/Card';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { AnnouncementItem } from 'pages/Announcements/AnnouncementItem';

export const TopPage: React.FC = () => {
  const { latest, isLoading, isError } = useLatestAnnouncements();
  if (isLoading || isError) {
    return <Spinner />;
  }

  return (
    <>
      <SectionHeading>新着情報</SectionHeading>
      <AnnouncementList items={latest.news} />
      <div className="mb-8 md:flex md:justify-center">
        <Link to="/announcements/news">
          <Button wide>お知らせ一覧へ</Button>
        </Link>
      </div>
      <SectionHeading>その他ご案内</SectionHeading>
      <AnnouncementList items={latest.guide} />
      <div className="mb-8 md:flex md:justify-center">
        <Link to="/announcements/guide">
          <Button wide>その他ご案内一覧へ</Button>
        </Link>
      </div>
      <div className="my-8">
        <div>
          <AnchorButton target="_blank" href="http://www.otatennis.jp/">
            <Typography type="large">大阪府テニス協会ホームページへ</Typography>
          </AnchorButton>
        </div>
        <div className="mt-8">
          <AnchorButton
            target="_blank"
            href={`${process.env.REACT_APP_PDF_ENDPOINT}/ranking_rules/20220401kitei.pdf`}>
            <Typography type="large">大阪ジュニアランキング規定</Typography>
          </AnchorButton>
        </div>
        <div className="mt-8">
          <Typography tag="p" type="large">
            団体登録、選手登録、大会申込は下記リンク
          </Typography>
          <AnchorButton
            target="_blank"
            href={`${process.env.REACT_APP_MEMBER_SITE_URL}`}>
            <Typography type="large">団体機能</Typography>
          </AnchorButton>
        </div>
      </div>
      <Sponsor />
    </>
  );
};

const AnnouncementList: React.FC<{ items: Announcement[] }> = ({ items }) => {
  if (items.length === 0) {
    return (
      <div className="py-8">
        <Typography type="large">新しいお知らせはありません</Typography>
      </div>
    );
  }

  const announcements = items.map((item) => (
    <AnnouncementItem key={item.id} announcement={item} page={0} />
  ));
  return (
    <div className="grid gap-x-6 grid-cols-1 md:grid-cols-2 mb-8">
      {announcements}
    </div>
  );
};

const Sponsor = () => {
  return (
    <>
      <SectionHeading>協賛企業</SectionHeading>
      <div
        className="flex flex-col items-center mx-auto mb-10"
        style={{ width: '240px' }}>
        <Card>
          <a
            href="https://sports.dunlop.co.jp/tennis/"
            target="_blank"
            rel="noopener noreferrer">
            <img src={dunlop} alt="DUNLOP" />
          </a>
        </Card>
      </div>
    </>
  );
};
