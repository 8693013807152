import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAnnouncement } from 'api/announcements';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { Spinner } from 'components/Loading/Spinner';
import { AnchorButton } from 'components/Button/AnchorButton';
import { PostContainer } from 'pages/shared/PostContainer';

export const AnnouncementPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { announcement, isLoading, isError } = useAnnouncement(Number(id));
  if (isLoading || isError) {
    return <Spinner />;
  }

  const handleAnchorClicked = () => {
    if (document.referrer) {
      history.push(new URL(document.referrer).pathname, { page });
      return;
    }

    history.goBack();
  };
  const page = new URLSearchParams(window.location.search).get('page') ?? '0';
  return (
    <>
      <Card>
        <Heading type="heading4">{announcement.announceTitle}</Heading>
        <Typography type="small" tag="p" className="font-bold">
          {announcement.registeredDate}
        </Typography>
        <div className="mt-5">
          <PostContainer content={announcement.body} />
        </div>
        {announcement.attachedFilePath && (
          <div className="mt-5">
            <AnchorButton target="_blank" href={announcement.attachedFilePath}>
              <Typography type="regular" className="font-bold">
                {extractFileName(announcement.attachedFilePath)}
              </Typography>
            </AnchorButton>
          </div>
        )}
      </Card>
      <div className="mt-5">
        <AnchorButton onClick={handleAnchorClicked}>
          <Typography>
            <svg
              className="stroke-current inline-block"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <path d="M19 12H6M12 5l-7 7 7 7" />
            </svg>
            一覧へ戻る
          </Typography>
        </AnchorButton>
      </div>
    </>
  );
};

const extractFileName = (url: string) => {
  const path = url.split('/');
  if (path.length === 0) {
    return '';
  }

  return path.reverse()[0];
};
