import React from 'react';

type HeadingTypes = 'heading1' | 'heading2' | 'heading3' | 'heading4';
type Props = {
  type?: HeadingTypes;
  className?: string;
};

export const Heading: React.FC<Props> = ({
  type = 'heading3',
  className,
  children,
}) => {
  switch (type) {
    case 'heading1': {
      const classNames = `text-3xl md:text-4xl font-bold ${className}`;
      return <h1 className={classNames}>{children}</h1>;
    }
    case 'heading2': {
      const classNames = `text-2xl md:text-3xl font-bold ${className}`;
      return <h2 className={classNames}>{children}</h2>;
    }
    case 'heading3': {
      const classNames = `text-xl md:text-2xl font-bold ${className}`;
      return <h3 className={classNames}>{children}</h3>;
    }
    case 'heading4': {
      const classNames = `text-lg md:text-xl font-bold ${className}`;
      return <h4 className={classNames}>{children}</h4>;
    }
    default: {
      const classNames = `text-2xl md:text-3xl font-bold ${className}`;
      return <h2 className={classNames}>{children}</h2>;
    }
  }
};
