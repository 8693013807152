import React from 'react';
import { useParams } from 'react-router-dom';
import { useDrawTables } from 'api/drawTables';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { Heading } from 'components/Typography/Heading';
import { Spinner } from 'components/Loading/Spinner';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { Card } from 'components/Card/Card';
import { DrawTable } from 'pages/Tournaments/DrawTable';
import { DAList } from 'pages/Tournaments/DAList';

export const GameResultPage: React.FC = () => {
  const { tournamentId, tennisEventId } = useParams<{
    tournamentId: string;
    tennisEventId: string;
  }>();
  const { result, isLoading, isError } = useDrawTables(
    tournamentId,
    tennisEventId
  );

  if (isLoading || isError) {
    return <Spinner />;
  }

  const drawTables = result.blocks
    .filter((block) => 0 < block.drawCount)
    .sort((a, b) => a.blockNumber - b.blockNumber)
    .map((block) => (
      <ListItem key={block.blockNumber}>
        <Card>
          <DrawTable
            block={block}
            players={result.assignedPlayers.filter(
              (o) => o.blockNumber === block.blockNumber
            )}
            isDoubles={result.isDoubles}
          />
          {block.blockNumber === 0 && <DAList players={result.daPlayers} />}
        </Card>
      </ListItem>
    ));

  return (
    <>
      <div className="flex justify-items-center">
        <SectionHeading>試合結果</SectionHeading>
      </div>
      <Heading>{result.tournamentName}</Heading>
      <Heading>{result.tennisEventName}</Heading>
      <List>{drawTables}</List>
    </>
  );
};
