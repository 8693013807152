import React from 'react';
import { AssignedPlayer, isBye } from 'api/drawTables';
import { Game } from 'pages/Tournaments/DrawTable';

type Props = { players: AssignedPlayer[]; games: Game[] };

export const Draw: React.FC<Props> = (props) => {
  const isDrawn = props.players.filter((o) => o.playerNames).length > 0;
  const totalDrawCount = props.games.length;
  const finalRound = Math.log2(totalDrawCount) + 1;
  const players = [...props.players].sort(
    (a, b) => a.drawNumber - b.drawNumber
  );

  const dataRows = players.map((item) => (
    <DataRow
      key={item.drawNumber}
      player={item}
      games={props.games}
      finalRound={finalRound}
      isDrawn={isDrawn}
    />
  ));

  return (
    <table
      style={{
        borderCollapse: 'collapse',
        whiteSpace: 'nowrap',
      }}>
      <thead>
        <HeaderRow finalRound={finalRound} />
      </thead>
      <tbody>{dataRows}</tbody>
    </table>
  );
};

const HeaderRow: React.FC<{ finalRound: number }> = ({ finalRound }) => {
  const headerRoundCells = displayRounds(finalRound).map((round) => (
    <th key={round} className="round">
      {roundName(finalRound, round)}
    </th>
  ));

  return (
    <tr>
      <th className="draw-number"></th>
      <th className="player-name">氏名</th>
      <th className="team-name">団体名</th>
      {headerRoundCells}
    </tr>
  );
};

const roundName = (finalRound: number, round: number): string => {
  if (finalRound - round === 0) {
    return '決勝';
  }
  if (finalRound - round === 1) {
    return '準決勝';
  }

  return `${toWide(round.toString())}回戦`;
};

// 1回戦は選手名、団体名を描画するので2回戦から表示。
const displayRounds = (finalRound: number) =>
  [...Array(finalRound)].slice(1).map((v, i) => i + 2);

type DataRowProps = {
  player: AssignedPlayer;
  games: Game[];
  finalRound: number;
  isDrawn: boolean;
};

const DataRow: React.FC<DataRowProps> = ({
  player,
  games,
  finalRound,
  isDrawn,
}) => {
  const createPosition = createPositionFactory(finalRound, player.drawNumber);
  const roundCells = displayRounds(finalRound).map((round) => (
    <RoundCell key={round} position={createPosition(round)} games={games} />
  ));

  return (
    <tr>
      <td className={`draw-${player.drawNumber} draw-number border-b`}>
        {`${toWide(player.drawNumber.toString())}．`}
      </td>
      <td className={`draw-${player.drawNumber} player-name border-b`}>
        {isDrawn && <PlayerNames assignedPlayer={player} />}
      </td>
      <td className={`draw-${player.drawNumber} team-name border-b`}>
        {multiline(player.teamAbbreviatedNames)}
      </td>
      {roundCells}
    </tr>
  );
};

type RoundCellProps = {
  position: Position;
  games: Game[];
};

const RoundCell: React.FC<RoundCellProps> = ({ position, games }) => {
  return (
    <td className={roundClassNames(position)}>
      {roundCellText(position, games)}
    </td>
  );
};

const PlayerNames: React.FC<{ assignedPlayer: AssignedPlayer }> = ({
  assignedPlayer,
}) => {
  if (isBye(assignedPlayer)) {
    return <p>Bye</p>;
  }

  const playerNames = multiline(assignedPlayer.playerNames);
  return <>{playerNames}</>;
};

const roundCellText = (
  position: Position,
  games: Game[]
): JSX.Element | JSX.Element[] | null => {
  if (position.hasPlayerName()) {
    return multiline(games[position.resultNumber].winnerNames);
  } else if (position.hasScore()) {
    return <>{games[position.resultNumber].gameScore}</>;
  }

  return null;
};

/*
  枠(Area)：勝者を描画する区画。
  勝者名は中央の上段、スコアは中央の下段に出力する。
  ※横線は枠の可視化のために引いており実際はない。
   round1 |round2 |round3 |round4 |
   -------|-------|-------|-------|
   name   |name   |       |       |
   name   |score  |name   |       |
          |-------|       |       |
   name   |name   |score  |       |
   name   |score  |       |name   |
          |-------|-------|       |
   name   |name   |       |score  |
   name   |score  |name   |       |
          |-------|       |       |
   name   |name   |score  |       |
   name   |score  |       |       |
   --------------------------------
*/

type Position = {
  hasPlayerName: () => boolean;
  hasScore: () => boolean;
  hasBorderRight: () => boolean;
  resultNumber: number;
};

const createPositionFactory = (finalRound: number, drawNumber: number) => (
  round: number
): Position => {
  const areaRowCount = 2 ** (round - 1);
  const rest = drawNumber % areaRowCount;
  const isLastRowInArea = rest === 0;
  // 枠内の位置（１オリジン）
  const position = isLastRowInArea ? areaRowCount : rest;
  const half = areaRowCount / 2;
  const quarter = Math.floor(areaRowCount / 4);
  const totalDrawCount = 2 ** (finalRound - 1);
  // 消化した試合数 = 総ドロー数 - 残ゲーム数
  // 残ゲーム数 = 2 ^ (最終ラウンド - (ラウンド - 1))
  const playedCount = totalDrawCount - 2 ** (finalRound - (round - 1));
  // 試合番号 = 消化した試合数 + ラウンド内の連番 + 補正値
  // ※ラウンド内の連番：ドロー番号を枠の行数で割った商。
  // ※補正値：枠内の最終行はポジションが割切れて繰り上がってるので不要。
  const resultNumber =
    playedCount +
    Math.floor(drawNumber / areaRowCount) +
    (isLastRowInArea ? 0 : 1);

  return {
    hasPlayerName: () => position === half,
    hasScore: () => position === half + 1,
    hasBorderRight: () => {
      if (round === 2) {
        // 下段なら横線引く
        return position === 2;
      }

      return quarter < position && position <= areaRowCount - quarter;
    },
    resultNumber: resultNumber,
  };
};

const roundClassNames = (position: Position) => {
  const classNames = ['round'];

  if (position.hasPlayerName()) {
    classNames.push('player-name');
  }

  if (position.hasScore()) {
    classNames.push('score');
  }

  if (position.hasPlayerName()) {
    classNames.push('border-b');
  }

  if (position.hasBorderRight()) {
    classNames.push('border-l');
  }

  return classNames.join(' ');
};

const toWide = (value: string): string => {
  return `${value}`.replace(/\d/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) + 65248)
  );
};

const multiline = (items?: string[]) =>
  items ? items.map((o, i) => <p key={i}>{o}</p>) : null;
