import React from 'react';
import {
  PlayersStoreProvider,
  usePlayersStore,
} from 'pages/Players/PlayersContext';
import { usePlayers } from 'api/players';
import { SearchForm } from 'pages/Players/SearchForm';
import { SectionHeading } from 'pages/shared/SectionHeading';
import { Typography } from 'components/Typography/Typography';
import { Spinner } from 'components/Loading/Spinner';
import { List } from 'components/List/List';
import { AnchorButton } from 'components/Button/AnchorButton';
import { PlayerItem } from 'pages/Players/PlayerItem';

export const PlayerListPage: React.FC = () => {
  return (
    <PlayersStoreProvider>
      <div className="flex justify-items-center">
        <SectionHeading>選手一覧</SectionHeading>
      </div>
      <div>
        <Typography tag="p">選手情報を検索します。</Typography>
        <Typography tag="p">
          検索ボタンを押すと検索結果を表示します。
        </Typography>
        <Typography tag="p">※どれか１つの条件だけでも検索できます。</Typography>
      </div>
      <SearchForm />
      <PlayerList />
    </PlayersStoreProvider>
  );
};

const PlayerList: React.FC = () => {
  const { state } = usePlayersStore();
  const { result, isLoading, isError } = usePlayers({
    playerName: state.playerName,
    gender: state.gender,
    page: state.page,
    canSearched: state.canSearched,
  });

  if (!state.canSearched) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <div className="py-8">
        <Typography type="large">読み込みに失敗しました。</Typography>
      </div>
    );
  }

  const playerList = result.players.map((item) => (
    <PlayerItem key={item.id} player={item} />
  ));

  return (
    <div className="mt-10">
      <div className="py-4">
        <Typography tag="p">
          {`${result.totalCount}人の選手が該当しました。`}
        </Typography>
      </div>
      {result.totalCount !== 0 && (
        <>
          <div className="grid grid-cols-2 border-b-2 border-gray">
            <div>
              <Typography className="font-bold">氏名</Typography>
            </div>
            <div>
              <Typography className="font-bold">団体名</Typography>
            </div>
          </div>
          <List>{playerList}</List>
          <div>
            <Pager current={result.page} last={result.lastPage} />
          </div>
        </>
      )}
    </div>
  );
};

const Pager: React.FC<{ current: number; last: number }> = ({
  current,
  last,
}) => {
  const { dispatch } = usePlayersStore();
  const handleClickPrevious = React.useCallback(
    () =>
      dispatch({ type: 'UPDATE_PAGE', payload: { page: `${current - 1}` } }),
    [dispatch, current]
  );
  const handleClickNext = React.useCallback(
    () =>
      dispatch({ type: 'UPDATE_PAGE', payload: { page: `${current + 1}` } }),
    [dispatch, current]
  );

  const previousButton =
    current !== 0 ? (
      <AnchorButton onClick={handleClickPrevious}>前へ</AnchorButton>
    ) : null;

  const nextButton =
    current !== last ? (
      <AnchorButton onClick={handleClickNext}>次へ</AnchorButton>
    ) : null;

  return (
    <div className="flex justify-around">
      <div>{previousButton}</div>
      <div>{nextButton}</div>
    </div>
  );
};
