import useSWR from 'swr';
import { LoadState } from 'api/SWRContext';

export type Player = {
  id: number;
  playerName: string;
  singlesRank: number;
  singlesPoint: string;
  doublesRank: number;
  doublesPoint: string;
  teamName: string;
  singlesPoints: EarnedPoint[];
  doublesPoints: EarnedPoint[];
  tournamentResults: TournamentResult[];
};

export type EarnedPoint = {
  pointEventId: number | null;
  pointEventName: string;
  point: number;
  tennisEventId: string;
  category: string;
  participationClassification: string;
  rank: number | null;
};

export type TournamentResult = {
  tournamentId: number;
  tennisEventId: string;
  tournamentName: string;
  tennisEventName: string;
  blockName: string;
  gameResults: GameResult[];
};

export type GameResult = {
  round: number;
  roundName: string;
  result: string;
  opponent: string;
  score: string;
};

export type PlayerHeader = Pick<Player, 'id' | 'playerName' | 'teamName'>;

type PlayersResult = {
  result: {
    totalCount: number;
    page: number;
    lastPage: number;
    players: PlayerHeader[];
  };
} & LoadState;

type PlayersParams = {
  playerName: string;
  gender: string;
  page: string;
  canSearched: boolean;
};

export const usePlayers = ({
  playerName,
  gender,
  page,
  canSearched,
}: PlayersParams): PlayersResult => {
  const params = {
    playerName,
    gender: parseValidGender(gender),
    page: page,
  };
  const queryString = new URLSearchParams(params).toString();
  const { data, error } = useSWR(
    canSearched ? `players${queryString ? '?' + queryString : ''}` : null
  );

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const parseValidGender = (gender: string) => {
  switch (gender) {
    case '1':
    case '2':
      return gender;
    default:
      return '';
  }
};

type PlayerResult = {
  player: Player;
} & LoadState;

export const usePlayer = (id: number): PlayerResult => {
  const { data, error } = useSWR(`players/${id}`);

  return {
    player: data,
    isLoading: !error && !data,
    isError: error,
  };
};
