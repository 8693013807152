import React from 'react';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const RadioButton: React.FC<Props> = ({ children, ...props }) => {
  if (!children) {
    return <input type="radio" {...props} />;
  }

  return (
    <label>
      <input type="radio" {...props} />
      <span>{children}</span>
    </label>
  );
};
