import React from 'react';
import { Player } from 'api/players';
import { Card } from 'components/Card/Card';
import { Typography } from 'components/Typography/Typography';

export const PlayerDetail: React.FC<{ player: Player }> = ({ player }) => {
  return (
    <Card>
      <div className="grid grid-cols-1 md:grid-cols-4">
        <Typography tag="p" className="font-bold">
          氏名
        </Typography>
        <Typography tag="p">{player.playerName}</Typography>
      </div>
      <div className="mt-5 grid grid-cols-2">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography tag="p" className="font-bold">
            シングルスランキング
          </Typography>
          <Typography tag="p">
            {player.singlesRank ? `${player.singlesRank}位` : ''}
          </Typography>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography tag="p" className="font-bold">
            ダブルスランキング
          </Typography>
          <Typography tag="p">
            {player.doublesRank ? `${player.doublesRank}位` : ''}
          </Typography>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-2">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography tag="p" className="font-bold">
            シングルスポイント
          </Typography>
          <Typography tag="p">{player.singlesPoint}</Typography>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography tag="p" className="font-bold">
            ダブルスポイント
          </Typography>
          <Typography tag="p">{player.doublesPoint}</Typography>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-4">
        <Typography tag="p" className="font-bold">
          団体名
        </Typography>
        <Typography tag="p">{player.teamName}</Typography>
      </div>
    </Card>
  );
};
