import useSWR from 'swr';
import { LoadState } from 'api/SWRContext';

export type Tournament = {
  id: number;
  tournamentName: string;
  outlineAttachedFilePath: string;
  scheduleAttachedFilePath: string;
  holdingPeriod: string;
  state: string;
  tennisEvents: TennisEvent[];
};

export type TennisEvent = {
  tennisEventId: string;
  tennisEventName: string;
  drawTableAttachedFilePath: string;
};

type TournamentsResult = {
  result: {
    seasonName: string;
    tournaments: Tournament[];
  };
} & LoadState;

export const useTournaments = (isPrevious = false): TournamentsResult => {
  const { data, error } = useSWR(`tournaments${isPrevious ? '/previous' : ''}`);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

type TournamentOutline = {
  id: number;
  tournamentName: string;
  outline: string;
  holdingPeriod: string;
};

type TournamentResult = {
  tournamentOutline: TournamentOutline;
} & LoadState;

export const useTournament = (id: number): TournamentResult => {
  const { data, error } = useSWR(`tournaments/${id}`);

  return {
    tournamentOutline: data,
    isLoading: !error && !data,
    isError: error,
  };
};
