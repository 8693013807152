import React from 'react';
import { TournamentResult, GameResult } from 'api/players';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { Card } from 'components/Card/Card';
import { Typography } from 'components/Typography/Typography';
import { LinkButton } from 'pages/shared/LinkButton';

export const TournamentResults: React.FC<{
  tournamentResults: TournamentResult[];
}> = ({ tournamentResults }) => {
  const results = tournamentResults.map((item) => (
    <TournamentResultItem
      key={`${item.tournamentId}_${item.tennisEventId}`}
      tournamentResult={item}
    />
  ));
  return <List>{results}</List>;
};

const TournamentResultItem: React.FC<{
  tournamentResult: TournamentResult;
}> = ({ tournamentResult }) => {
  return (
    <ListItem>
      <Card>
        <div>
          <LinkButton
            textStyle="RegularBold"
            to={`/tournaments/${tournamentResult.tournamentId}/result/${tournamentResult.tennisEventId}`}>
            {`${tournamentResult.tournamentName} ${tournamentResult.blockName}`}
          </LinkButton>
        </div>
        <div>
          <LinkButton
            textStyle="RegularBold"
            to={`/rankings/${tournamentResult.tennisEventId}`}>
            {tournamentResult.tennisEventName}
          </LinkButton>
        </div>
        <div>
          <GameResults gameResults={tournamentResult.gameResults} />
        </div>
      </Card>
    </ListItem>
  );
};

const GameResults: React.FC<{ gameResults: GameResult[] }> = ({
  gameResults,
}) => {
  const results = gameResults.map((item) => (
    <GameResultItem key={item.round} gameResult={item} />
  ));

  return (
    <>
      <div className="text-center mt-5 grid grid-cols-2 border-b-2 border-gray">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography className="font-bold md:hidden">試合/勝敗</Typography>
          <Typography className="font-bold hidden md:block">試合</Typography>
          <Typography className="font-bold hidden md:block">勝敗</Typography>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Typography tag="p" className="font-bold md:hidden">
            対戦相手/スコア
          </Typography>
          <Typography className="font-bold hidden md:block">
            対戦相手
          </Typography>
          <Typography className="font-bold hidden md:block">スコア</Typography>
        </div>
      </div>
      {results}
    </>
  );
};

const GameResultItem: React.FC<{ gameResult: GameResult }> = ({
  gameResult,
}) => {
  return (
    <div className="text-center grid grid-cols-2 py-2 border-b border-gray">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <Typography>{gameResult.roundName}</Typography>
        <Typography>{gameResult.result}</Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <Typography>{gameResult.opponent}</Typography>
        <Typography>{gameResult.score}</Typography>
      </div>
    </div>
  );
};
