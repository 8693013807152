import React from 'react';
import { Link } from 'react-router-dom';
import { FancyAnchorButton } from 'components/Button/AnchorButton';
import { Heading } from 'components/Typography/Heading';
import { Typography } from 'components/Typography/Typography';

type TextStyle = 'ItemHeader' | 'RegularBold' | 'Regular';
type Props = {
  to: string;
  textStyle: TextStyle;
};

const Wrapper: React.FC<{ textStyle: TextStyle }> = ({
  textStyle,
  children,
}) => {
  switch (textStyle) {
    case 'ItemHeader':
      return <Heading type="heading4">{children}</Heading>;

    case 'RegularBold':
      return <Typography className="font-bold">{children}</Typography>;

    case 'Regular':
      return <Typography>{children}</Typography>;
  }
};

export const LinkButton: React.FC<Props> = ({ to, textStyle, children }) => {
  return (
    <Link to={to} component={FancyAnchorButton}>
      <Wrapper textStyle={textStyle}>{children}</Wrapper>
    </Link>
  );
};
