import useSWR from 'swr';
import { LoadState } from 'api/SWRContext';

export type Announcement = {
  id: number;
  announceTitle: string;
  body: string;
  registeredDate: string;
  attachedFilePath: string;
};

export type AnnouncementType = 'news' | 'guide';

type Params = {
  announcementType: AnnouncementType;
  page: number;
};

type AnnouncementsResult = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  announcements: Announcement[];
} & LoadState;

export const useAnnouncements = ({
  announcementType,
  page,
}: Params): AnnouncementsResult => {
  const { data, error } = useSWR(
    `announcements/${announcementType}?page=${page}`
  );

  return {
    announcements: data?.announcements,
    hasPreviousPage: data?.hasPreviousPage ?? false,
    hasNextPage: data?.hasNextPage ?? false,
    isLoading: !error && !data,
    isError: error,
  };
};

type AnnouncementResult = {
  announcement: Announcement;
} & LoadState;

export const useAnnouncement = (id: number): AnnouncementResult => {
  const { data, error } = useSWR(`announcements/${id}`);

  return {
    announcement: data,
    isLoading: !error && !data,
    isError: error,
  };
};

type LatestAnnouncementsResult = {
  latest: {
    news: Announcement[];
    guide: Announcement[];
  };
} & LoadState;

export const useLatestAnnouncements = (): LatestAnnouncementsResult => {
  const { data, error } = useSWR('announcements/latest');

  return {
    latest: data,
    isLoading: !error && !data,
    isError: error,
  };
};
