import React from 'react';
import { RankingPlayer } from 'api/rankings';
import { ListItem } from 'components/List/ListItem';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { LinkButton } from 'pages/shared/LinkButton';

export const RankingPlayerItem: React.FC<{ rankingPlayer: RankingPlayer }> = ({
  rankingPlayer,
}) => {
  return (
    <ListItem>
      <Card>
        <div className="text-center grid grid-cols-ranking-row md:grid-cols-ranking-row-tablet">
          <div className="md:text-right md:pr-4">
            <Typography>{rankingPlayer.rank}</Typography>
          </div>
          <div className="md:text-right md:pr-16">
            <Typography>{rankingPlayer.point}</Typography>
          </div>
          <div className="md:text-left">
            <LinkButton
              textStyle="RegularBold"
              to={`/players/${rankingPlayer.id}`}>
              {rankingPlayer.playerName}
            </LinkButton>
          </div>
          <div className="truncate md:text-left">
            <Typography>{rankingPlayer.teamName}</Typography>
          </div>
        </div>
      </Card>
    </ListItem>
  );
};
