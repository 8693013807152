import { useSWRInfinite } from 'swr';
import { LoadState } from 'api/SWRContext';

export type Ranking = {
  rankingName: string;
  updatedDateTime: string;
  players: RankingPlayer[];
};

export type RankingPlayer = {
  id: number;
  rank: number;
  point: number;
  playerName: string;
  teamName: string;
};

type RankingResult = {
  ranking: Ranking;
  isLoadingMore: boolean;
  isReachingEnd: boolean;
  size: number;
  next: () => void;
} & LoadState;

export const useRanking = (
  tennis_event_id: string,
  page: number
): RankingResult => {
  const { data, error, size, setSize } = useSWRInfinite<Ranking>(
    (pageIndex) => `ranking/${tennis_event_id}?page=${pageIndex}`,
    { initialSize: page }
  );

  const ranking = flat(data);
  const isLoading = !error && !data;
  const isLoadingMore =
    isLoading || !!(size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.players.length === 0;
  const isReachingEnd =
    isEmpty || !!(data && data[data.length - 1]?.players.length === 0);

  return {
    ranking,
    isLoading,
    isLoadingMore,
    isReachingEnd,
    isError: error,
    size,
    next: () => setSize(size + 1),
  };
};

const flat = (rankings: Ranking[] | undefined): any => {
  if (rankings == null) {
    return null;
  }

  const ranking = rankings[0];
  const rankingPlayers = rankings.flatMap((item) => item.players);
  return { ...ranking, ...{ players: rankingPlayers } };
};
