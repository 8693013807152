import React from 'react';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export const HamburgerButton: React.FC<Props> = ({ isOpen, onClick }) => {
  const icon = isOpen ? (
    <>
      <line x1="2" y1="2" x2="18" y2="18" strokeWidth="3" />
      <line x1="2" y1="18" x2="18" y2="2" strokeWidth="3" />
    </>
  ) : (
    <>
      <rect x="0" y="3" width="20" height="2" />
      <rect x="0" y="9" width="20" height="2" />
      <rect x="0" y="15" width="20" height="2" />
    </>
  );

  return (
    <svg
      onClick={onClick}
      className="h-8 w-8 stroke-current text-dark-gray cursor-pointer">
      {icon}
    </svg>
  );
};
