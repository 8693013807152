import React from 'react';
import { routes } from 'routes';
import { Link } from 'react-router-dom';

type Menu = {
  path: string;
  title: string;
  order: number;
};

const menu = routes
  .filter((o) => o.menu != null)
  .map((o) => {
    return { path: o.path, title: o.menu?.title, order: o.menu?.order } as Menu;
  });
menu.sort((a, b) => a.order - b.order);

type Props = {
  handleMenuClicked: () => void;
};

export const Navigation: React.FC<Props> = ({ handleMenuClicked }) => {
  return (
    <ul className="bg-white md:flex md:justify-between md:bg-transparent">
      {menu.map((item, index) => (
        <li
          key={index}
          className="border-b-2 first:border-t-2 border-secondary-light md:border-none md:text-center md:w-full md:h-full">
          <Link
            to={item.path}
            onClick={handleMenuClicked}
            className="block p-5 text-gray-dark font-bold md:hover:bg-secondary">
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
