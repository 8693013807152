import React from 'react';
import { convertToDom } from 'lib/domConverter';
import { Announcement } from 'api/announcements';
import { ListItem } from 'components/List/ListItem';
import { Typography } from 'components/Typography/Typography';
import { Card } from 'components/Card/Card';
import { LinkButton } from 'pages/shared/LinkButton';

type Props = {
  key: number;
  announcement: Announcement;
  page: number;
};

export const AnnouncementItem: React.FC<Props> = ({ announcement, page }) => {
  const parsed =
    convertToDom(announcement.body) || document.createElement('div');
  return (
    <ListItem>
      <Card>
        <div>
          <LinkButton
            textStyle="ItemHeader"
            to={`/announcements/${announcement.id}?page=${page}`}>
            {announcement.announceTitle}
          </LinkButton>
        </div>
        <Typography type="small" tag="p">
          {announcement.registeredDate}
        </Typography>
        <div className="h-8">
          <Typography tag="p" className="truncate">
            {parsed.textContent}
          </Typography>
        </div>
      </Card>
    </ListItem>
  );
};
