import React from 'react';
import { usePlayersStore } from 'pages/Players/PlayersContext';
import { Typography } from 'components/Typography/Typography';
import { TextField } from 'components/Form/TextField';
import { RadioButton } from 'components/Form/RadioButton';
import { Button } from 'components/Button/Button';

export const SearchForm: React.FC = () => {
  const { dispatch } = usePlayersStore();
  const [playerName, setPlayerName] = React.useState('');
  const [gender, setGender] = React.useState('0');

  const handleChangedPlayerName = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) =>
      setPlayerName(e.currentTarget.value),
    [setPlayerName]
  );
  const handleChangedGender = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => setGender(e.currentTarget.value),
    [setGender]
  );
  const handleClick = React.useCallback(
    () =>
      dispatch({
        type: 'UPDATE_PARAM',
        payload: { playerName, gender, page: '0' },
      }),
    [dispatch, playerName, gender]
  );

  return (
    <div className="flex flex-col md:flex-row md:place-items-center md:mt-6">
      <div className="mt-6 md:mt-0">
        <Typography tag="p" className="font-bold">
          氏名
        </Typography>
      </div>
      <div className="pt-4 md:pt-0 md:px-8">
        <TextField value={playerName} onChange={handleChangedPlayerName} />
      </div>
      <div className="mt-6 md:mt-0">
        <Typography tag="p" className="font-bold">
          性別
        </Typography>
      </div>
      <div className="flex pt-4 md:pt-0 md:px-8">
        <div className="pr-16">
          <RadioButton
            name="gender"
            value="0"
            checked={gender === '0'}
            onChange={handleChangedGender}>
            未指定
          </RadioButton>
        </div>
        <div className="pr-16">
          <RadioButton
            name="gender"
            value="1"
            checked={gender === '1'}
            onChange={handleChangedGender}>
            男子
          </RadioButton>
        </div>
        <div className="pr-16">
          <RadioButton
            name="gender"
            value="2"
            checked={gender === '2'}
            onChange={handleChangedGender}>
            女子
          </RadioButton>
        </div>
      </div>
      <div className="mt-6 md:mt-0">
        <Button wide onClick={handleClick}>
          検索
        </Button>
      </div>
    </div>
  );
};
